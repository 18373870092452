import {createAsyncThunk} from "@reduxjs/toolkit";
import {API_URL, useHttp, headers} from "../../../shared/api/base";


export const employerThunk = createAsyncThunk(
    'users/employerThunk',
    async (userData) => {
        const {request} = useHttp();
        return await request(`${API_URL}api/users/`, "POST", JSON.stringify(userData), headers())
    }
)


export const fetchUsersData = createAsyncThunk(
    'users/fetchRoomsData',
    async () => {
        const { request } = useHttp();
        return await request(`${API_URL}api/users/`, 'GET', null, headers())
    }
);

export const queryFilterUserThunk = createAsyncThunk(
    'users/queryFilterUserThunk',
    async (id) => {
        const {request} = useHttp();
        return await request(`${API_URL}api/TelegramUsers/?status=${id}`, "GET", null, headers())
    }
)

