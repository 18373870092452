import React from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'shared/ui/modal';
import { Button } from 'shared/ui/button';
import cls from './questionDeleteModal.module.sass';
import alertIcon from 'shared/assests/icon/alert.svg'
import {questionDeleteModalThunk} from "../model/questionDeleteModalThunk";
import {fetchQuestionData} from "pages/questions/ui/questionThunk";


export const QuestionDeleteModal = ({ isOpen, onClose, roomId }) => {
    const dispatch = useDispatch();

    const handleDelete = () => {
        dispatch(questionDeleteModalThunk(roomId)).then(() => {
            dispatch(fetchQuestionData())
        })
        onClose()
    };

    if (!isOpen) return null;
    return (
        <Modal active={isOpen} setActive={onClose}>
            <div className={cls.filter}>
                <div className={cls.deleteHead}>
                    <img src={alertIcon} alt=""/>
                    <h1>Ogohlantirish !</h1>
                </div>
                <div className={cls.deleteText}>
                    <h2>Siz buni o'chirmoqchimisiz ?</h2>
                </div>
                <div className={cls.deleteButtons}>
                        <Button extraClass={cls.deleteButton} children={"Ha"} onClick={handleDelete}/>
                    <Button extraClass={cls.cancelButton} children={"Yo'q"} onClick={onClose}/>
                </div>
            </div>
        </Modal>
    );
};
