import { useCallback, useState } from 'react';
import { Link } from "../../../shared/ui/link";
import { routersConfig } from "../../../app/routers";
import cls from "./menuBar.module.sass";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff, faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { Button } from "../../../shared/ui/button";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { getUserName } from "../../../features/login";

export const Menubar = () => {
    const [active, setActive] = useState(false);
    const [isOpen, setIsOpen] = useState(false); // Hamburger menyu holati
    const userRole = useSelector(getUserName); // Foydalanuvchining rolini olamiz
    const navigate = useNavigate();
    const onClickExit = () => {
        navigate("/");
        sessionStorage.clear();
        localStorage.clear();
    };

    const toggleMobileMenu = () => {
        setIsOpen(!isOpen);
    };

    const renderMultipleMenu = useCallback(() => {
        return routersConfig.map((item, index) => {
            if (!item.isMenu) return null;
            if (item.role && !item.role.includes(userRole)) return null;

            return (
                <div key={index}>
                    <li className={cls.link}>
                        <Link
                            to={item.to}
                            extraClass={cls.link__href}
                            activeClass={cls.active}
                            onClick={() => {
                                setActive(item.name);
                                setIsOpen(false);
                            }}
                        >
                            <FontAwesomeIcon icon={item.icon} className={cls.iconLink} />
                        </Link>
                    </li>
                </div>
            );
        });
    }, [userRole]);

    const renderedMenu = renderMultipleMenu();

    return (
        <>
            <nav className={cls.menu}>
                <ul className={cls.menu__inner}>
                    {renderedMenu}
                </ul>
                <Button onClick={onClickExit} extraClass={cls.button}>
                    <FontAwesomeIcon icon={faPowerOff} />
                </Button>
            </nav>

            <nav className={cls.mobileMenu}>
                <Button onClick={toggleMobileMenu} extraClass={cls.hamburger}>
                    <FontAwesomeIcon icon={isOpen ? faTimes : faBars} />
                </Button>
                {isOpen && (
                    <ul className={cls.mobile_menu_inner}>
                        {renderedMenu}
                        <Button onClick={onClickExit} extraClass={cls.button}>
                            <FontAwesomeIcon icon={faPowerOff} />
                        </Button>
                        <Button onClick={() => setIsOpen(false)}>Yopish</Button>
                    </ul>
                )}
            </nav>
        </>
    );
};
