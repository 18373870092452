import React, {useEffect, useState} from 'react';
import { Table } from "shared/ui/table";
import {Button} from "../../../shared/ui/button";
import cls from './questionList.module.sass'
import {faCheck, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {QuestionAnswerModal} from "../../../features/questionAnswerModal";
import {fetchQuestionData} from "../../../pages/questions";
import {getQuestionsData} from "../../../pages/questions";
import {useDispatch, useSelector} from "react-redux";
import {QuestionDeleteModal} from "../../../features/questionDeleteModal";
import {getQuestionLoading} from "../../../pages/questions";
import {DefaultPageLoader} from "../../../shared/ui/defaultLoader";
export const QuestionList = () => {
    const dispatch = useDispatch();
    const questionData = useSelector(getQuestionsData);
    const questionLoading = useSelector(getQuestionLoading)
    const [selectedQuestionId, setSelectedQuestionId] = useState(null); // Modal uchun tanlangan savol IDsi
    const [actives, setActives] = useState(false);
    const [isOpen, setIsOpen] = useState(false)
    const [quesId, setQuesId] = useState(1)

    useEffect(() => {
        dispatch(fetchQuestionData());
    }, []);

    const renderVacancies = () => {
        return questionData.map((item) => (
            <tr key={item.id}>
                <td>{item.id}</td>
                <td style={{textAlign: "start"}}>{item.name}</td>
                <td style={{display: "flex", textAlign: "start"}}>
                    <Button
                        extraClass={cls.uzBtn}
                        children={item.lang === 1 ? "uz" : "ru"}
                        onClick={() => {
                            setSelectedQuestionId(item.id)
                            setActives(true);
                        }}
                    />
                </td>
                <td>
                    {
                        item.status === true ? (
                            <Button extraClass={cls.trueBtn} children={<FontAwesomeIcon icon={faCheck}/>} onClick={() => setActives(false)} />
                        ) : (
                            <Button extraClass={cls.falseBtn} children={<FontAwesomeIcon icon={faXmark}/>} onClick={() => {
                                setQuesId(item.id)
                                setIsOpen(true)}
                            } />
                        )
                    }
                </td>
            </tr>
        ));
    };

    return (
        <Table>
            <thead>
            <tr>
                <th>№</th>
                <th style={{width: "100rem", textAlign: "start"}}>Savol</th>
                <th style={{textAlign: "start"}}>Tahrirlash</th>
                <th></th>
            </tr>
            </thead>
            <tbody>
            {
                questionLoading ? <DefaultPageLoader/> :
                renderVacancies()
            }
            <QuestionAnswerModal setActives={setActives} actives={actives} selectedQuestionId={selectedQuestionId} />
            </tbody>
            <QuestionDeleteModal roomId={quesId} isOpen={isOpen} onClose={() => setIsOpen(false)}/>
        </Table>
    );
};
